body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Trabajar con Firefox */
* {
    scrollbar-width: thin; /* "auto" o "thin" */
    scrollbar-color: rgb(200, 140, 51) rgb(237, 191, 16) orange; /* scroll de desplazamiento y pista */
}

/* Trabajar con Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px; /* Ancho de toda la barra de desplazamiento */
}

*::-webkit-scrollbar-track {
    background: rgb(237, 191, 16); /* Color del área de seguimiento */
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(200, 140, 51); /* Color del scroll de desplazamiento */
    border-radius: 20px; /* Ronda del scroll de desplazamiento */
    border: 3px solid rgb(200, 140, 51); /* Crea relleno alrededor del scroll de desplazamiento */
}

code {
    background-color: #23272a;
    border: 1px solid rgb(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 4px;
    display: inline-block;
    text-align: center;
}

.codeCopy {
    cursor: pointer;
    color: #ffd700;
}

.navbar {
    box-shadow: 2px 2px 5px #000000c9;
}

footer {
    background-color: #191919;
    border-top: 1px solid rgba(50, 50, 50, 0.3);
    box-shadow: 0px 0px 5px #000000c9;
    margin-top: 5%;
    width: 100%;
    /* position: fixed; */
    bottom: 0;
}

.economy {
    padding: 8px 0 8px 0;
}

.e-var {
    background-color: #ffffff15;
    border-radius: 15px;
}

.channelUl {
    margin: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    min-height: 52px;
}

.channelUl li {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.channelCard {
    box-sizing: border-box;
    vertical-align: baseline;
    justify-content: center;
    height: 42px;
    max-width: 200px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 600;
    margin-inline-end: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 15px;
    background-color: rgb(52, 56, 60);
}

.channelCard button {
    display: flex;
    background: transparent;
    border: none;
    margin-left: 5px;
    opacity: 0.5;
}

.channelButtonIcon {
    font-size: 15px;
    color: #fff;
}

.channelButtonAdd {
    height: 34px;
    width: 34px;
    border-radius: 100%;
    border-color: #ffffff50;
    background: transparent;
    opacity: 0.8;
}

.caja-discord {
    color: #9c9c9c;
    background-color: #23272a;
    border: 1px solid rgb(30, 32, 32);
    border-radius: 5px;
    padding: 4px;
    display: inline-block;
    text-align: center;
}
